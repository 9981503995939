.react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  border: none !important;
  border-radius: 7px;
  background: #cccccc;
}

.react-multi-carousel-dot-list {
  position: unset !important;
  margin-top: 10px !important;
}

.react-multi-carousel-dot--active button {
  background-color: #006daf !important;
}

.react-multi-carousel-list {
  position: unset !important;

  overflow: unset !important;
  overflow-x: clip !important;
}
.react-multiple-carousel__arrow--right {
  right: -40px !important;
}

.react-multiple-carousel__arrow--left {
  left: -40px !important;
}

.custom-carousel-wrapper {
  position: relative;
  width: 100%;

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-list {
    overflow: hidden; // Hide overflow for smoother centering
  }
}

// Additional style to center the item in the carousel
@media (max-width: 480px) {
  .react-multi-carousel-item {
    justify-content: center; // Center the single item on mobile
  }
}
